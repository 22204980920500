
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "verify-user",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      let userInfos = route.query;
      store
        .dispatch(Actions.VERIFY_REGISTRATION, userInfos)
        .then(() => {
          router.push({ name: "homepage" });
        })
        .catch(() => {
          router.push({ name: "sign-up" });
        });
    });
  },
});
